import React, { useContext, useState } from "react";
import {IconButton, Grid, Tooltip} from "@mui/material/"
import {useNavigate} from "react-router-dom"
import Paths from "../utils/paths";
import { ArrowBack } from '@mui/icons-material';
import { SearchBox } from "@SaferPlaces2023/sui-vite"
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";


export const NewProjectTools = () => {

    let navigate = useNavigate()
    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState("") // eslint-disable-line
    const [project, setProject ] = useContext(QgisContext)
    const Q = new QgisProject(project, setProject)

    const handleBack = () => {
        navigate(Paths.MY_PROJECTS)
    }

    const handleSelect = (option) => {
        setSelected(option);
        if (option?.properties?.extent){
            Q.zoomToExtent(option.properties.extent, "EPSG:4326")
        }
        
    }

    return (

        <Grid container justifyContent="flex-end" columnSpacing={3} >

            <Grid item>
                <Tooltip title="Back to My Projects">
                    <IconButton onClick={handleBack} variant="contained" color="primary"> 
                        <ArrowBack />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item style={{ width:"400px"}}>
                <SearchBox 
                    value={search}
                    onChange={(e)=>setSearch(e.target.value)}
                    placeholder="Search"
                    onSelect={handleSelect}
                />
            </Grid>
           
        </Grid>

    )
}