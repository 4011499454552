import { justext } from "./filesystem"
import { b64encode, isArray, isString, len } from "./strings"
import { user_delete, user_get, user_post } from "./http"
import { __drive__, getUserName } from "./const"

export const addjob = function (params) {
  
  //! questo dà errore: se params.jid è undefined, non si può assegnare a undefined
  //params.jid = params.jid || ((new Date().getTime())+parseInt(Math.random()*1000000))

  if (!params.jid) {
    params = {
      ...params,
      jid: (new Date().getTime()) + parseInt(Math.random()*1000000),
    }
  }
  
  const userdir = `${__drive__}/projects/${getUserName()}`
  const project_dir = `${userdir}/${params.project}`

  if (params.type === "eedem") {
      /*************************************************************************************************
       *                                  EEDEM
       *************************************************************************************************/
      let bbox = params.bbox
      if (isArray(bbox)){
        //Reversing coordinates to satify eedem
        bbox = [ params.bbox[1], params.bbox[3], params.bbox[0], params.bbox[2]] 
      }else if (isString(bbox)){
        bbox = bbox.startsWith(__drive__)?bbox:`${__drive__}/${bbox}`
      }

      let fileout =  `${userdir}/${params.out}`
      params.command = `eedem --dmg --of COG --debug --dataset "${params.dataset}" --band "${params.band}" --bbox "${bbox}" --t_srs "${params.t_srs}" --out "${fileout}"`
      //params.command = `eedem --dmg --sea --of COG --dataset "${params.dataset}" --band "${params.band}" --bbox "${bbox}" --t_srs "${params.t_srs}" --out "s3://saferplaces.co/eedem/${justfname(fileout)}"`

      if (params.dataset === "ESA/WorldCover/v100"){
        params.command += ` --inf`
      }      
  
  } else if (params.type === "safer001") {
    /*************************************************************************************************
     *                                    SAFER001
     *************************************************************************************************/
    //params.bbox is a file.tif
    params.command = `safer001 --bbox "${params.bbox}" --dem "${params.dem}" --start "${params.start}" --time-lag ${params.time_lag} --sar "${params.filesar}" --opt "${params.fileopt}" --gfm "${params.filegfm}" --mode ${params.mode} --ee-private-key /run/secrets/saferplaces-ff265cd723a0 --verbose`

  } else if (params.type === "cosmo") {
    /*************************************************************************************************
     *                                    COSMO - SAFER001
     *************************************************************************************************/
    params.command = `cosmo --image cosmo_safer001 --cosmo "${params.cosmo}" --out "${params.out}" --mode ecs --vcpu 2 --ram 16384 --debug`

  } else if (params.type === "safer002") {
    /*************************************************************************************************
     *                                    SAFER002
     *************************************************************************************************/
    //params.bbox is a file.tif
    //let fileout =  `s3://saferplaces.co/projects/${getUserName()}/${params.project}/${justfname(params.filedtm)}`
    //let fileout =  forceext(params.filedtm, "002.tif")
    params.command = `safer002 --image safer002 --bbox "${params.bbox}" --year 2023 --mode ${params.mode} --out ${params.fileout} --vcpu 4 --ram 30720 --debug`
  
  } else if (params.type === "safer003") {
    /*************************************************************************************************
     *                                    SAFER003
     *************************************************************************************************/

    //params.bbox is a file.tif
    //                safer003 --bbox 12,44,12.5,44.5  --start 2022-11-22        --time-lag 1                  --time-step daily      --chrs-account bargiotti@meeo.it --pdir-outputdir chrs_output --debug
    params.command = `safer003 --bbox "${params.bbox}" --start "${params.start}" --time-lag ${params.time_lag} --time-step ${params.time_step} --mode ${params.mode} --debug`
    params.command += ` --chrs-account bargiotti@meeo.it`
    params.command += ` --pdir-outputdir ${project_dir}/chrs_output`

  } else if (params.type === "dem_preprocess") {

      /*************************************************************************************************
       *                                  DEM PREPROCESS
       *************************************************************************************************/
      params.command = `safer_rain_script --pre --dem "${params.filedtm}" --building "${params.fileobm}"`

  } else if (params.type === "safer_rain_preprocess") {
      /*************************************************************************************************
       *                                  SAFER RAIN PREPROCESS
       *************************************************************************************************/
      params.command = `safer_rain_script --pre --model safer_rain --dem "${params.filedtm}" --mode ${params.mode}`
  
  } else if (params.type === "safer_coast_preprocess") {
      /*************************************************************************************************
       *                                  SAFER COAST PREPROCESS
       *************************************************************************************************/ 
      //parallel computation on same files can lead to io errors
  
      params.command = `safer_rain_script --pre --model safer_coast --dem "${params.filedtm}" --mode ${params.mode}`
     
      
  } else if (params.type === "safer_rain_flooding" || params.type === "safer_river_flooding") {
    /*************************************************************************************************
     *                                  SAFER RAIN FLOODING
     *************************************************************************************************/

    params.command = `safer_rain_script --type ${params.type} --model ${params.model} --rain "${params.rain}" --dem "${params.filebld}" --out "${params.out}" --verbose`

    if (params.dmg){
      params.command += ` --dmg --building "${params.fileobm}"`
    }

    if (params.mode){
      params.command += ` --mode ${params.mode}`

    if (params.mode==="lambda")
      params.command += ` --image algo --vcpu 1 --ram 2048 --timeout 240`
    }

    if (params.model==="untrim" && params.man){
      params.command += ` --man ${params.man}`
    }

    if (params.model==="untrim" && params.nl){
      params.command += ` --nl ${params.nl}`
    }

    if (params.model==="untrim" && params.tmax){
      params.command += ` --tmax ${params.tmax}`
    }
    
    if (params.model==="untrim" && params.ti){
      params.command += ` --ti ${params.ti}`
    }

    if (params.model==="untrim" && params.duration>=0){
      params.command += ` --duration ${params.duration}`
    }

    if (params.model==="untrim" && params.delt>=0){
      params.command += ` --delt ${params.delt}`
    }

    if (params.rain && isString(params.rain) && justext(params.rain)==="shp"){
      params.command += ` --rain-fname "${params.rain_fname}"`
    }

    if (params.ga) {
      params.command += ` --ga --duration ${params.duration} --clay "${params.clay}" --sand "${params.sand}"`
      
      if (params.sand_fname){
        params.command += ` --sand-fname ${params.sand_fname}`
      }

      if (params.clay_fname){
        params.command += ` --clay-fname ${params.clay_fname}`
      }

      if (params.ir){
        params.command += ` --ir "${params.ir}" --ir-fname ${params.ir_fname}`
      }
    }

    if (params.storage && len(params.storage_selection)) {
      params.command += ` --storage "${params.storage}" --storage-fname "v" --storage-selection ${params.storage_selection.join(",")}`
    }

    if (params.note) {
      params.command += ` --jdesc "${b64encode(params.note)}"`
    }
    console.log(params.command)
  } else if (params.esl) {
    /*************************************************************************************************
     *                                  SAFER COAST FLOODING
     *************************************************************************************************/ 
    //params.command = `safer_rain_script --model safer_coast --model ${params.model} --dem "${params.filebld}" --esl ${params.esl} --out "${params.out}"  --verbose`
    params.command = `safer_rain_script --model ${params.model} --dem "${params.filebld}" --esl ${params.esl} --out "${params.out}"  --verbose`

    if (params.dmg){
      params.command += ` --dmg --building "${params.fileobm}"`
    }
    
    if (params.model==="untrim" && params.duration>=0){
      params.command += ` --duration ${params.duration}`
    }


    if (params.mode){
      params.command += ` --mode ${params.mode}`

      if (params.mode==="lambda")
          params.command += ` --image algo --vcpu 1 --ram 2048 --timeout 240`
    }

    if (params.r){
      params.command += ` --r ${params.tana}`
    }

    if (params.model==="untrim" && params.esl>0){
      //const filesea = forceext(params.filebld, "seamask.tif")
      //params.command += ` --sea-mask ${filesea}`
    }

    if (params.model==="untrim" && params.man){
      params.command += ` --man ${params.man}`
    }
    
    if (params.model==="untrim" && params.nl){
      params.command += ` --nl ${params.nl}`
    }

    if (params.model==="untrim" && params.tmax){
      params.command += ` --tmax ${params.tmax}`
    }

    if (params.model==="untrim" && params.ti){
      params.command += ` --ti ${params.ti}`
    }

    if (params.model==="untrim" && params.delt>=0){
      params.command += ` --delt ${params.delt}`
    }

    if (params.barrier_selection.length) {
      let feature_list = params.barrier_selection
        .map((feature) => ""+feature)
        .join(",")
      params.command += ` --barrier "${params.filebarrier}" --barrier-fname height --barrier-selection=${feature_list}`
    }

    if (params.note) {
      params.command += ` --jdesc "${b64encode(params.note)}"`
    }
  
  } else if (params.type==="safer_damage") {
  /*************************************************************************************************
   *                                  SAFER DAMAGE
   *************************************************************************************************/
    params.command = `safer_damage --building "${params.fileobm}" --water "${params.filewd}"  --out "${params.filedmg}"`
  } else{

    params.command = ""
  } 
  //endif

  console.log(params.command)

  // for any job
  let datetime = new Date()
  params.starttime = datetime.toISOString()
  params.endtime = datetime.toISOString()
  params.description = b64encode(params.note)
  return user_post("/api/job/0", params)
}

export const listjob = ()=> user_get("/api/jobs").catch((error) => {console.log(error)})
  
export const removejob = (Q, id) =>{
  let url = id ? `/api/job/${id}` : "/api/jobs"
  let params = {"project": Q.getProjectName() }
  //if id is not present it removes all jobs
  return user_delete(url, params).then(response=>{
      if (Q && response && response.data){
          response.data.map( (item) =>{  
            if (item.startsWith("DMG_")) {
              Q.removeLayer(item) 
            } else if (item.startsWith("SF001_")) {
              Q.removeLayer("OPT_"+item) 
              Q.removeLayer("SAR_"+item)
              Q.removeLayer("GFM_"+item) 
            } 
             else {
              Q.removeLayer("WD_"+item)
              Q.removeLayer("DMG_"+item) 
            }
            return true
          })
      }

  })
}




