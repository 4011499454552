import React, { useContext, createContext, useEffect, useState} from "react";
import { QgisProject, QgisContext, Tools} from "@SaferPlaces2023/safer-map";
import { CreateShapeFileIfNotExists } from "../ShapeFile";
import { fireEvent } from "../utils/events";
import { len, listify } from "../utils/strings";

export const ToolsContext = createContext({
    params: {},
    setParams: () => {}
});

/** //? Questi sono i tool importati dalla classe Tools da safer-map. Mancano le jsdoc, usare questa lista come reference
static BBOX                 = "bbox"
static PAN                  = "Pan"
static ZOOM_TO_SELECTION    = "Rectangle"
static RULER                = "MeasureTool"
static RAIN_SELECT          = "RainSelect"              //Avoid changing this name because they are buddies
static RAIN_DRAW            = "RainDraw"                //Avoid changing this name
static BARRIER_SELECT       = "BarrierSelect"           //Avoid changing this name
static BARRIER_DRAW         = "BarrierDraw"             //Avoid changing this name
static STORAGE_TANK_SELECT  = "StorageTankSelect"       //Avoid changing this name
static STORAGE_TANK_DRAW    = "StorageTankDraw"         //Avoid changing this name
static RIVER_EVENT_SELECT   = "RiverEventSelect"        //Avoid changing this name
static RIVER_EVENT_DRAW     = "RiverEventDraw"          //Avoid changing this name
static INFILTRATION         = "InfiltrationTool"
static INFILTRATION_SELECT  = "InfiltrationSelect"
static IDENTIFY             = "Identify"
static VOLUME               = "VolumeTool"
static DAMAGE               = "DamageSelect"
static SECTION              = "SectionDraw"
static BLUESPOTS            = "BluespotTool"
static BLUESPOT_SELECT      = "BluespotSelect"
static MITIGATIONS          = "BuildingSelect"
 */




/**
 * This component is responsible for managing the tools. When a tool is set to active/inactive, it will be controlled and if its valid
 * it will be associated with the corresponding tool on the map. 
 * @returns 
 */
export const ToolManager = () => {

    const [activeTool, setActiveTool] = useContext(ToolsContext)
    const [userLayers, setUserLayers] = useState()

    const [project, ] = useContext(QgisContext)
    let Q = new QgisProject(project)

    let allLayers = project.map ? project.map.getLayers().getArray().map(layer => layer.get("id")).filter(Boolean) : []
    //const baseLayers = allLayers.filter(id => {return id.match(/Google|Stamen/)})
    // Patch to remove base layers from the list of all layers
    allLayers = allLayers.filter(id => !id.match(/Google|Stamen/))
    /**
     * Return a list of all the currently visible layers
     * @returns {string[]} list of visible layers
     */
    function getActiveLayers() {
        return project.map ? 
                project.map.getLayers().getArray().filter((layer) => layer.getVisible()).map(layer => layer.get("id")).filter(Boolean) 
                : []
    }


    /**
     * Return a list of all the layers associated with the specified tags
     * @param {string[]} tags Tag or list of tags
     * @returns {string[]} layer or list of layers associated with the tag
     */
    function getLayerIdsByTag(tags) {
        
        tags = listify(tags)

        let layers = []
        tags.forEach(tag => layers.push(...Q.getMapLayersByTag("type", tag)))

        // 2023-04-29 patch to add layers by name also
        for (let layername of tags) {
            let maplayer = Q.getMapLayer(layername)
            if (maplayer) {
                layers.push(maplayer)
            }
        }        
        // --- 
        layers = layers.map(maplayer => maplayer.id)
        
        // keep unique values
        layers = [...new Set(layers)]

        return layers
    }
    

    /**
     * @param {*} tool 
     * @returns {String} the layer name associated with the tool
     */
    function getLayers(tool) {
        if (!Tools.exists(tool)) {
            console.error(`Tool ${tool} does not exist`)
            return undefined
        }

        switch (tool) {
            case Tools.RAIN_SELECT:                
            case Tools.RAIN_DRAW:                   return { working: "rainfall",     visible: "rainfall"}

            case Tools.BARRIER_SELECT:              
            case Tools.BARRIER_DRAW:                return { working: "barrier",      visible: ["barrier", "dtm"] }

            case Tools.STORAGE_TANK_SELECT:         
            case Tools.STORAGE_TANK_DRAW:           return { working: "storagetank",  visible: "storagetank"}

            case Tools.RIVER_EVENT_SELECT:          
            case Tools.RIVER_EVENT_DRAW:            return { working: "riverevent",   visible: "riverevent"}

            case Tools.BLUESPOTS:                  
            case Tools.BLUESPOT_SELECT:             return { working: "watersheds",    visible: "watersheds"}

            case Tools.DAMAGE_PREVIEW:              
            case Tools.MITIGATIONS:                 return { working: "buildings",    visible: "buildings"}
            
            case Tools.INFILTRATION:                return { working: "infiltration_rate",    visible: "infiltration_rate"}
            case Tools.SECTION:                     return { working: "dtm" ,     visible: "dtm"}
            case Tools.DAMAGE:                      return { working: "damage",   visible: null}
            default:                                return undefined
        }        
    }



    function getLayerGroup(tool) {
        if (!Tools.exists(tool)) {
            console.error(`Tool ${tool} does not exist`)
            return undefined
        }
        switch (tool) {
            case Tools.RAIN_SELECT:                 return "Rain"
            case Tools.RAIN_DRAW:                   return "Rain"
            case Tools.BARRIER_SELECT:              return "Mitigations"
            case Tools.BARRIER_DRAW:                return "Mitigations"
            case Tools.STORAGE_TANK_SELECT:         return "Mitigations"
            case Tools.STORAGE_TANK_DRAW:           return "Mitigations"
            case Tools.RIVER_EVENT_SELECT:          return "River"
            case Tools.RIVER_EVENT_DRAW:            return "River"

            case Tools.BLUESPOTS:                   return "Bluespots"
            case Tools.MITIGATIONS:                 return "Mitigations"
            case Tools.INFILTRATION:                return "Digital Twin"
            case Tools.INFILTRATION_SELECT:         return "Digital Twin"

            default:                                return "" 
        }
    }

    /** 
     * True if the tool has an exclusive set of layers. In that case, when the tool is activated 
     * the layers will be shown and all the others will be hidden.
     * These layers are the following:
     * ```
     * BARRIER_DRAW, BARRIER_SELECT, RAIN_DRAW, RAIN_SELECT, STORAGE_TANK_DRAW, STORAGE_TANK_SELECT, RIVER_EVENT_DRAW, RIVER_EVENT_SELECT, MITIGATIONS, SECTION
     * ```
     * @param {String} tool 
     * @returns  {boolean} 
     */
    function isExclusive (tool) {
        return [
            Tools.BARRIER_DRAW, Tools.BARRIER_SELECT,
            Tools.RAIN_DRAW, Tools.RAIN_SELECT,
            Tools.STORAGE_TANK_DRAW, Tools.STORAGE_TANK_SELECT,
            Tools.RIVER_EVENT_DRAW, Tools.RIVER_EVENT_SELECT,
            Tools.INFILTRATION_SELECT,
            Tools.MITIGATIONS, Tools.DAMAGE_PREVIEW,
            Tools.BLUESPOT_SELECT, 
        ].includes(tool)
    }


    /** 
     * True if the tool requires a shapefile which is created if not exists. 
     * These tools are the following:
     * ```  
     * RAIN_SELECT, RAIN_DRAW, BARRIER_SELECT, BARRIER_DRAW, STORAGE_TANK_SELECT, STORAGE_TANK_DRAW, RIVER_EVENT_SELECT, RIVER_EVENT_DRAW
     * ```
     * @param {String} tool Name of the tool
     * @returns {boolean} 
     */
    const isCreativeTool = (tool) => {
        return [ 
            Tools.RAIN_SELECT, Tools.RAIN_DRAW, 
            Tools.BARRIER_SELECT, Tools.BARRIER_DRAW, 
            Tools.STORAGE_TANK_SELECT, Tools.STORAGE_TANK_DRAW, 
            Tools.RIVER_EVENT_SELECT, Tools.RIVER_EVENT_DRAW
        ].includes(tool)
    }

   

    /**
     * Show / hide a layer or a list of layers
     * @param {any} layerName Name of the layer or list of layers
     * @param {boolean} visible True if the layer should be visible (optional. Default: true)
     * @param {boolean} exclusive True if the specified layers should be the only visible layer (optional. Default: true)
     */
    const activateLayers = (ids, visible=true, exclusive=true) => {
        
        //let ids = getLayerIdsByTag(ids) 

        ids.forEach(id => Q.setVisible(id, visible))
        if (exclusive) {
            const otherLayers = allLayers.filter(layer => !ids.includes(layer))
            activateLayers(otherLayers, false, false)
        }
    }

    
    /**
     * Copy the following comments but with a * in front of each line
     * 
     * Activate a tool on the map and the layers associated with it.
     * Different cases:
     * 
     * (1)  layerNames is undefined.
     *      The tool does not work with any layer (rectangle, ruler, pan, ...). In this case the tool is activated directly
     * 
     * (2)  layerNames is a list of strings.
     *      the tool works with more layers (section, ...)
     * 
     * (3)  layerNames is a string
     *      the tool works with a single layer (barrier, rain, ...)
     *      (3.1) it may require that a shp file be created if not present. Happens in cases where the tool allows you to CREATE a feature (barrier, rain, river, storagetank)
     *      (3.2) does not require the creation of a shp file. Happens in cases where the tool allows you to SELECT a feature (buildings, ...)
     * 
     * @param {string} toolName     name of the tool to be activated
     * @param {string} layerName    name of the layer (or list of layers names) to be activated with the tool
     * @param {string} eventName    name of the event layer to be activated with the tool 
     */
    function activateToolAndLayers (tool, layerName, eventName) {
        //console.log("activateToolAndLayers: ", tool)
        if (!tool)                  activateToolAndLayers(Tools.PAN)
        if (!Tools.exists(tool))    return console.error(`Tool ${tool} does not exist`)
        if (layerName)              project.map.removeTool(tool)

        let associatedLayers = getLayers(tool),
            workingLayers = null, 
            visibleLayers = null
            
        //console.log("associatedLayers: ", associatedLayers)

        // se ci sono layer associati al tool, uso quelli, altrimenti uso il layerName dove presente
        if (associatedLayers) {
           
            if (len(getLayerIdsByTag(associatedLayers.working))){
                workingLayers = getLayerIdsByTag(associatedLayers.working)
            }else{
                workingLayers = [ associatedLayers.working ]
            }
           
            visibleLayers = getLayerIdsByTag(associatedLayers.visible)
        } else {
            
            workingLayers = layerName ? [layerName] : null   //layername
            visibleLayers = layerName ? [Q.getMapLayerByName(layerName).id] : null
        }
        
        // console.log("******\nactivateToolAndLayers: ", tool, layerName, eventName)
        // console.log(" -> associated layers: ", associatedLayers)
        // console.log(" -> working layers: ", workingLayers)
        // console.log(" -> visible layers: ", visibleLayers)
        // console.log("\n****************\n\n")

        let groupName = getLayerGroup(tool)
        let layers = []

        

        if (!workingLayers || !workingLayers.length) {  
            project.map.activateTool(tool, true)
        // } else if (workingLayers instanceof Array) { //? ora il workingLayers è sempre un array
        }  else if (workingLayers.length > 1) {
            layers = workingLayers.map((id) => project.map.getLayerByName(id))
            project.map.removeTool(tool)
            project.map.activateTool(tool, true, {layers: layers, events: eventName}, groupName)
        } else {                                                                                                            // (3)              
            if (isCreativeTool(tool)) {                                                                                  // (3.1)    // ? single layer (e.g. Damage tool)            
                //console.log("tool: ", tool, "workingLayers: ", workingLayers, "groupName: ", groupName)
                CreateShapeFileIfNotExists(project, workingLayers[0], groupName)
                .then((response) => {
                    //console.log("response: ", response)
                    layers = listify(project.map.getLayerByName(response.layername)) 
                    project.map.removeTool(tool)
                    project.map.activateTool(tool, true, {layers: layers}, groupName)
                })
            } else {                                                                                                       // (3.2)    // ? single layer (e.g. Barrier tool) 
                layers = listify(project.map.getLayerByName(workingLayers[0]))
                project.map.removeTool(tool)
                project.map.activateTool(tool, true, {layers: layers, events: eventName}, groupName)
            }
        }

        let layersToShow = []                               
        let activeLayers = getActiveLayers()                          

        if (visibleLayers) {                                                                      // if the tool works with layers               
            layersToShow = isExclusive(tool) ? [visibleLayers] : visibleLayers.concat(activeLayers) //  if the tool is exclusive we show only the layers associated with it. Otherwise we also the currently visible layers
            if (!userLayers) setUserLayers(activeLayers)                                            //  then, store the currently visible layers if needed
        } else {                                                                                    // otherwise, if the tool does not work with layers
            if (userLayers) {                                                                       //  if we were working with some layers
                layersToShow = userLayers                                                           //   we show them
                setUserLayers(null)                                                                 //   and reset the user layers
            } else {                                                                                //  otherwise if we were not working with any layer       
                layersToShow = activeLayers                                                         //   we keep the currently visible layers
            }
        }

        layersToShow = layersToShow.flat()
        activateLayers(layersToShow)

        // Tools side effects
        fireEvent(tool === Tools.SECTION ? "section:drawstart" : "section:close")
        fireEvent("bluespot-tool:identify", { visible: (tool === Tools.BLUESPOT_SELECT)} ) 
        fireEvent("identify", { visible: (tool === Tools.IDENTIFY)} ) 
    }

    /*  This effect is triggered when the status 'activeTool' is changed by the function 'setActiveTool'. 
        It will activate the tool on the map and the layer associated with it.

        Sometimes the activeTools needs a specific layer choosen by the user (eg. infiltration, bluespot)
        For this reason we cant retrieve the layer from the tool name only, as in the other cases. 
        In this case, the layer is passed as a parameter to the tool.  
        
        If a string is passed to setActiveTool, it is interpreted as the tool name while layerName and eventName will be undefined.
        Otherwise, if an object is passed, it is interpreted as the tool object and layerName and eventName will be taken from it.
    */
    useEffect(() => {

        if (!project || !project.map)                       { return } 
        if (!activeTool)                                    { console.error("[ToolManager]\nactiveTool is undefined: ", activeTool); return}
        if (!Tools.exists(activeTool.name || activeTool))   { console.error("[ToolManager]\nTool '" + (activeTool.tool || activeTool) + "' not available. Choose one of the following:\n" + Tools.all().join(" - ")); setActiveTool(Tools.PAN); return }

        // build the object
        let tool = { 
            name: activeTool.name || activeTool,            
            layerName: activeTool.layerName,            
            eventName: activeTool.eventName                 
        }

        console.log("[USE-EFFECT] ToolManager: ", activeTool)
        activateToolAndLayers(tool.name, tool.layerName, tool.eventName)

    }, [activeTool]) // eslint-disable-line 
    
    return <></>
}



