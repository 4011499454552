import axios from "axios"
import { isArray } from "./strings"
import { getProjectName, getToken, getUserName, developers } from "./const"


const HEADERS = () => {
  return {
    Authorization: `Token ${getToken()}`, 
    Projectname: getProjectName(),
    "Content-Type": "application/json",
  }
}

/**
 * URLParams
 * The difference between this function and the URLSearchParams constructor is that this function
 * replcate the key for array params in order to create foo=1&foo=2&foo=3 instead of foo=1,2,3
 */
export const URLParams = (params) => {

  const queryParams = new URLSearchParams()
  
  for (const key in params) {
    if (isArray(params[key])) {
      for (const val of params[key]) {
        queryParams.append(key, val);
      }
    } else {
      queryParams.append(key, params[key]);
    }
  }
  return queryParams.toString()
}


/**
 * http
 */
export const http = axios.create({

  headers:{
    "Content-Type": "application/json",
  },
  redirect: "follow",
  referrerPolicy: "no-referrer",
  cache: "no-cache",
});


/**
 * user_post
 * @param {*} url 
 * @param {*} data 
 * @returns 
 */
export const user_post = (url, data) => {

  const headers = HEADERS()
  return http.post(url, data, { headers }).catch(console.log);
};

/**
 * user_get
 */
export const user_get = (url, data) => {

  const headers = HEADERS()
  const queryParams = URLParams(data) //This create a get/delete string
  // make a 
  return http.get(`${url}?${queryParams}`, { headers }).catch(console.error);
};


/**
 * user_put
 * @param {*} url 
 * @param {*} data 
 * @returns 
 */
export const user_put = function (url, data) {
  //let headers = { Authorization: "Token " + localStorage.getItem("token") };
  const headers = HEADERS()
  return http.put(url, data, { headers }).catch(console.log);
};


/**
 * user_patch
 * @param {*} url 
 * @param {*} data 
 * @returns 
 */
export const user_patch = function (url, data) {
  const headers = HEADERS()
  return http.patch(url, data, { headers }).catch(console.log);
};


/**
 * user_delete
 * @param {*} url 
 * @returns 
 */
export const user_delete = function (url, params) {
  const headers = HEADERS()
  const queryParams = URLParams(params) //This create a get/delete string
  return http.delete(`${url}?${queryParams}`, { headers }).catch(console.error);
};


/**
 * api_features
 * @param {*} params 
 * @returns 
 */
export const api_features = function (params) {
    return user_post("/api/features", params).then((response) => response.data)
};

/**
 * patch
 */
export const patch = (projectname) =>{
  projectname = projectname || "*"
  return user_post(`/api/patch/${projectname}`)
}

/**
 * upload
 * @param {*} file_obj 
 * @param {*} onUploadProgress 
 * @returns 
 */
export const upload = ( file_obj, cmap="viridis", t_srs="", onUploadProgress=()=>{}) => {
  let data = new FormData();
  // append files and their names
  data.append("file", file_obj, file_obj.name)
  data.append("cmap", cmap)

  if (t_srs){
    data.append("t_srs", t_srs)
  }

  return http.post("/api/upload/geodata", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Token " + getToken(),
      Projectname: getProjectName(),
    },
    onUploadProgress,
  });
};


export async function isfile(projectname, filename){

    let data = {
      projectname: projectname,
      filename:filename
    }

    let result = await user_post("/api/isfile", data)
    return result
}

/**
 * logout
 * @returns always true 
 */
export const logout = ()=>{
    
    http.post("/api/logout").then(()=>{sessionStorage.clear()})
    return true
}


/**
 * isDeveloper
 * @returns 
 */
export const isDeveloper = ()=>{
  return developers.includes(getUserName())
}

/**
 * isRerUser
 * @returns 
 */
export const isRerUser = ()=>{

  //return getUserName().endsWith("regione.emilia-romagna.it") || getUserName() === "valluzzi@gmail.com"
  return false && getUserName() === "valluzzi@gmail.com"
}
