import React from "react";
import { useState } from "react"
import {Box, Grid, Paper, TextField, Button, IconButton, Tooltip} from "@mui/material"
import { LoadingButton } from '@mui/lab';

import { useNavigate } from 'react-router-dom';
import { http } from '../utils/http';
import Paths from "../utils/paths";
import {loginBoxStyle, buttonStyle} from "../themes"
import background from "../images/rect5.png"
import saferplaces_logo from "../images/Safer Places - Logo.png";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function LoginPage() {

    let navigate = useNavigate()

    const [state, setState] = useState({username: "", password: ""})
    const [loginError, setLoginError] = useState({error: false, msg:""})            // text shown under the input filed which describes the error situation
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [loginWaiting, setLoginWaiting] = useState(false)

    const handleUsernameChange = (event) => {

        let text = `${event.target.value}`.trim().toLowerCase()
        
        // remove all characters that are not valid for file names
        text = text.replace(/[^a-z0-9-@.]/g, "")

        // trim .. from the username
        text = text.replace("..", "")

        setState({...state, username: text})
    }

    const handlePasswordChange = (event) => {

        setState({...state, password: `${event.target.value}`})
    }
    

    const handleChangePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible)
    }

    function handleGoToForgotPassword() {
        sessionStorage.setItem("email", state.username)
        navigate(Paths.FORGOT_PASSWORD)
    }

    function handleGoToSignup() {
        navigate(Paths.SIGNIN)
    }
    
    const passwordVisibilityToggle = () => {
        if (passwordVisible) {
            return <VisibilityOffIcon />
        } else {
            return <VisibilityIcon />
        }
    }

    const handleLogin = (e) => {
        setLoginWaiting(true)
        http.post("/api/login", {"username": state.username, "password": state.password})
            .then(response => {
                
                if (response.data.key) {
                    sessionStorage.setItem("username", state.username)
                    sessionStorage.setItem("token", response.data.key)
                    setState(prevState => ({...prevState, logged: true, open:false, snackbar:true}));
                    navigate(Paths.MY_PROJECTS)
                } else {
                    setLoginError({error: true, msg:response.data.message})
                    setLoginWaiting(false)
                }
            })
            .catch((error) => {
                setLoginError({error: true, msg:"Undefined error"})
                setLoginWaiting(false)
            })
    }


    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            sx = {{backgroundImage:`url(${background})` }}
        >
            
        <Paper elevation={10} sx={loginBoxStyle}>

            <Grid container direction="column" aling="center">

                <Grid item >
                    <img src={saferplaces_logo} alt="saferplaces logo" height="40" style={{ padding: 5 }} />
                </Grid>

                <Grid item>
                <h2>Login</h2>
                </Grid>
                
                
                <Grid item>
                <TextField 
                    label="Username" 
                    name="username"
                    variant="outlined" 
                    fullWidth 
                    value={state.username} 
                    onChange={handleUsernameChange}
                    error={loginError.error}
                    margin="dense"
                    
                    />
                </Grid>

                <Grid item>
                <TextField 
                    type= {passwordVisible ? "text" : "password"}
                    
                    label="Password" 
                    name="password"
                    variant="outlined" 
                    fullWidth value={state.password} 
                    onChange={handlePasswordChange}
                    helperText={loginError.msg}
                    error={loginError.error}
                    autoFocus
                    margin="dense"
                    InputProps={{
                       
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={passwordVisible ? "Hide password" : "Show password"}>                                
                                    <IconButton onClick={handleChangePasswordVisibility}>
                                        {passwordVisibilityToggle()}
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                            
                          ),
                      }}
                    />
                </Grid>
                <Grid item>
                    {/* <Link to={Paths.FORGOT_PASSWORD} onClick={handleGoToForgotPassword}>
                    Forgot my password
                    </Link><br/> */}
                    <Button variant="text" onClick={handleGoToForgotPassword}>Forgot my password</Button>
                </Grid>
                
                <Grid item>

                    
                    <LoadingButton type="submit" 
                            fullWidth 
                            color="primary" 
                            onClick={handleLogin}
                            variant="contained"
                            loading = {loginWaiting}
                            sx={buttonStyle}>
                        Confirm
                    </LoadingButton>
   
               
                </Grid>
                

                <Grid item>
                <Button fullWidth type="text" onClick={handleGoToSignup}> Create an account </Button>
                </Grid>
               
             
            </Grid>
                
        </Paper>
        </Box>        
    )
}


