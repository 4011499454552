import { Slider, Typography } from "@mui/material"

// Floodplains, heavy trees, underbrush: 0.150
export const ManningSlider = ({ value, onChange }) => {

    const marks = [
        
            { name: "Earth, straight, uniform", minValue: 0.018, maxValue: 0.025 },
            { name: "Earth, winding, sluggish", minValue: 0.025, maxValue: 0.035 },
            { name: "Natural channels, clean, straight", minValue: 0.025, maxValue: 0.040 },
            { name: "Natural channels, sluggish with pools", minValue: 0.035, maxValue: 0.050 },
            { name: "Floodplains, dense brush", minValue: 0.070, maxValue: 0.160 },
            { name: "Floodplains, heavy trees, underbrush", minValue: 0.100, maxValue: 0.200 },
            { name: "Concrete, trowel finish", minValue: 0.012, maxValue: 0.014 },
            { name: "Concrete, unfinished", minValue: 0.014, maxValue: 0.017 },
            { name: "Asphalt", minValue: 0.013, maxValue: 0.015 },
            { name: "Brickwork", minValue: 0.012, maxValue: 0.015 },
            { name: "Cast iron pipes", minValue: 0.010, maxValue: 0.015 },
            { name: "Corrugated metal pipes", minValue: 0.022, maxValue: 0.027 },
            { name: "Short grass, clipped", minValue: 0.025, maxValue: 0.035 },
            { name: "Medium grass", minValue: 0.030, maxValue: 0.050 },
            { name: "Tall grass, uncut", minValue: 0.035, maxValue: 0.050 },
            { name: "Dense weeds, high grass", minValue: 0.035, maxValue: 0.070 },
            { name: "Rock, gravelly bed", minValue: 0.025, maxValue: 0.035 },
            { name: "Rocky, mountain streams", minValue: 0.030, maxValue: 0.060 },
            { name: "Coarse gravel, uniform size", minValue: 0.024, maxValue: 0.028 },
            { name: "Fine gravel", minValue: 0.022, maxValue: 0.025 },
            { name: "Light brush, no trees", minValue: 0.035, maxValue: 0.050 },
            { name: "Medium to heavy brush", minValue: 0.050, maxValue: 0.080 },
            { name: "Debris-filled channel", minValue: 0.050, maxValue: 0.150 }        
    ]
       
    const items = marks.filter(mark => (value >= mark.minValue && value <= mark.maxValue)).sort((a, b) => a.minValue - b.minValue)
    //get teh first item in the array
    const item = (items.length)?items[0]:null

    return (
        <>
        
        <Slider
            value={value}
            onChange={onChange}
            valueLabelDisplay="auto"
            step={0.001}
            marks={marks}
            min={0.01}
            max={0.2}
        />
        <Typography>
           {item.name} [{item.minValue},{item.maxValue}]
        </Typography>
        
        </>
    )

}//end ManningSlider