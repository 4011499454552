import { Slider } from "@mui/material"



export const TimeShootSlider = ({ value, onChange }) => {

    const marks = [
        {label:"10'", value:600},
        {label:"15'", value:900},
        {label:"30'", value:1800},
        {label:"1h", value:3600},
        {label:"2h", value:7200},
        {label:"3h", value:10800},
    ]

    const valueLabelFormat = (value) => `${value}s`

    return (
        
        <Slider 
            value={value}
            onChange={onChange}
            valueLabelDisplay="on"
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            step={60}
            marks={marks}
            min={600}
            max={10800}
        />

    )

}//end ManningSlider